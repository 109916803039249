* {
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Oswald', sans-serif; */
}

.app {
  /* background-color: #282c34; */
  /* color: white; */
  background-color: #eee;


  /* background-color: white; */
  color: black;
  /* color: white !important; */
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 100px;
}

.nav {
  /* margin-left: 50px; */
  display: flex;
  column-gap: 1.5rem;
}

.nav a {
  text-decoration: none;
}

.container {
  width: 70%;
  margin: 0 auto;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .container {
    width: 90%;
  }

  .nav-container {
    justify-content: center;
  }

  .nav {
    display: none;
  }
}

a {
  color: #fff;
}